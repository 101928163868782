import React from 'react'
import { Link } from 'react-router-dom'

const StreetMba = () => {
  return (
    <>
        {/* Street MBA Program */}
        <div>
          <div className="px-4 py-4 sm: py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
              <div className="lg:w-1/2">
                <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                  The Street MBA
                </h2>
              </div>
              <div className="lg:w-1/2">
                <p className="text-base text-gray-700 md:text-md">
                  We live in what is probably the most competitive era in all of history. Never before this era could a business exist in Nairobi and its direct competitor is in china. Never before could a business exist with one person – the visionary – and have all other functions be outsourced from different parts of the world. Some of the topics covered include:
                </p>
              </div>
            </div>
            <ul className="mt-8 md:grid md:grid-cols-4 gap-6">
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black">
                    Understand the dynamics of the world in which we now live.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black ">
                    Gain insights into the hanging behavior of customers.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black ">
                    Understanding global trends and how to adapt your business to them.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black ">
                    Build a sustainable family business.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black ">
                    Global Selling: The Who, the What and the Where.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black">
                    Competing in the age of digital platforms.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black ">
                    The new face of marketing: Making the connection where it counts.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black ">
                    Board Selection Processes.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black ">
                    Governance Structures to make you investor worthy.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black ">
                    The Laws of Sale: How to transform a business from X to 10x.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black">
                    Cyber Security for Non Tech People.
                  </span>
                </div>
              </li>
              <li className="mt-6 lg:mt-0">
                <div className="flex">
                  <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                      </path>
                    </svg>
                  </span>
                  <span className="ml-4 text-base leading-6 font-medium text-black ">
                    Starting with Zero: From the brain to the bank
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">
            <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
              <div className="lg:py-6 lg:pr-16 text-black">
                <h2 className="text-4xl my-4 font-bold">How to join.</h2>
                <div className="flex">
                  <div className="flex flex-col items-center mr-4">
                    <div>
                      <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                        <svg className="w-4 text-gray-600" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                          <line fill="none" strokeMiterlimit={10} x1={12} y1={2} x2={12} y2={22} />
                          <polyline fill="none" strokeMiterlimit={10} points="19,15 12,22 5,15" />
                        </svg>
                      </div>
                    </div>
                    <div className="w-px h-full bg-gray-300" />
                  </div>
                  <div className="pt-1 pb-8">
                    <p className="mb-2 text-lg font-bold">Step 1</p>
                    <p className="text-gray-700">
                      Visit the registration page using the Join us button provided below and provide the requested information on the registration form, a confirmation email will be sent to the email address you have provided.</p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col items-center mr-4">
                    <div>
                      <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                        <svg className="w-4 text-gray-600" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                          <line fill="none" strokeMiterlimit={10} x1={12} y1={2} x2={12} y2={22} />
                          <polyline fill="none" strokeMiterlimit={10} points="19,15 12,22 5,15" />
                        </svg>
                      </div>
                    </div>
                    <div className="w-px h-full bg-gray-300" />
                  </div>
                  <div className="pt-1 pb-8">
                    <p className="mb-2 text-lg font-bold">Step 2</p>
                    <p className="text-gray-700">
                      Confirm your registration using the link sent to your email.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col items-center mr-4">
                    <div>
                      <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                        <svg className="w-4 text-gray-600" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                          <line fill="none" strokeMiterlimit={10} x1={12} y1={2} x2={12} y2={22} />
                          <polyline fill="none" strokeMiterlimit={10} points="19,15 12,22 5,15" />
                        </svg>
                      </div>
                    </div>
                    <div className="w-px h-full bg-gray-300" />
                  </div>
                  <div className="pt-1 pb-8">
                    <p className="mb-2 text-lg font-bold">Step 3</p>
                    <p className="text-gray-700">
                      Choose your preferred payment method and pay the
                      {/* <span className="text-green-700 font-bold">$1000/month</span> */}
                       subscription fee
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col items-center mr-4">
                    <div>
                      <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                        <svg className="w-4 text-gray-600" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                          <line fill="none" strokeMiterlimit={10} x1={12} y1={2} x2={12} y2={22} />
                          <polyline fill="none" strokeMiterlimit={10} points="19,15 12,22 5,15" />
                        </svg>
                      </div>
                    </div>
                    <div className="w-px h-full bg-gray-300" />
                  </div>
                  <div className="pt-1 pb-8">
                    <p className="mb-2 text-lg font-bold">Step 4</p>
                    <p className="text-gray-700">
                      If payment is successful, you will receive a confirmation email. Use the link provided to set your password.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col items-center mr-4">
                    <div>
                      <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                        <svg className="w-6 text-gray-600" stroke="currentColor" viewBox="0 0 24 24">
                          <polyline fill="none" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} points="6,12 10,16 18,8" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="pt-1">
                    <p className="mb-2 text-lg font-bold">Success! You are now part of the Street MBA program.</p>
                    <Link to="/register/street-mba" className="inline-block px-5 py-3 mt-8 text-lg font-medium text-white bg-blue-500 rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline" >
                    Join us</Link>
                  </div>
                </div>
              </div>
              <div className="sm:mt-20">
                <img className="object-cover w-full h-56 rounded shadow-lg sm:h-120" src="/thestreetuniversity_joygroup.jpg" loading="lazy" alt="The Street University Street MBA Program" />
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default StreetMba