import React, { useState } from "react";
import apiClient from "../../services/api";
import Alert from "../Alert";
import Spinner from '../../components/Spinner';

const BsAccelerator = () => {
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        assistance: "",
        tell_us_more: ""
    });

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);


        apiClient.post("/bsaccelerator/application", data).then((response) => {
            if (response.status === 201 && response.data.status === "success") {
                setStatus(response.data.status);
                setMessage(response.data.message);
                setIsLoading(false);

                if (response.data.status === 'success') {
                    window.location.pathname = "/confirmation/business_accelerator";
                }
                else {
                    setStatus("error");
                    setMessage("Technical error occured, please contact administrator")
                }
            }
            setTimeout(() => {
                setData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone: "",
                    assistance: "",
                    tell_us_more: ""
                });
                setStatus("");
                setMessage("");
            }, 2000);
        });
    };

    const submitValues = (e) => {
        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
                        <div className="flex">
                            <div className="pt-6 pb-8">
                                <h2 className="text-3xl font-extrabold text-black sm:text-4xl sm:leading-[3.5rem]">
                                    <span className="block">
                                        Business Accelerator Program Application
                                    </span>
                                    <span className="block text-indigo-500">
                                        Get to the next level.
                                    </span>
                                </h2>
                                <p className="text-base text-black md:text-md mt-4 sm:mt-8">
                                    This unique service of the Street University helps aspiring entrepreneurs to take their business ideas from concept to consumer. We give the developing companies access to mentoring, strategic support, registrations and all logistical help, recruiting the right teams. getting the business going, monitoring and evaluation where necessary and much more.
                                </p>
                            </div>
                        </div>


                        <div className="relative text-black">
                            <div className="card form-card px-3 sm:px-8">
                                <div className="card-body">
                                    {status !== "" && <Alert status={status} message={message} />}

                                    <form id="reg-form" method="POST" className="form" action="" onSubmit={handleSubmit}>
                                        <input type="hidden" name="action" value="businessAcceleratorRegistration" />
                                        <input type="hidden" name="schema" value="streetuni" />
                                        <h3 className="text-3xl font-bold my-6">Apply</h3>
                                        <hr className="mb-6" />
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-4">
                                                <div className="form-group">
                                                    <label className="text-md font-bold block mb-4">First Name *</label>
                                                    <input type="text" onChange={submitValues} value={data.first_name} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="first_name" placeholder="Enter First Name" name="first_name" required />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4">
                                                <div className="form-group">
                                                    <label className="text-md font-bold block mb-4">Last Name *</label>
                                                    <input type="text" onChange={submitValues} value={data.last_name} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="last_name" placeholder="Enter Last Name" name="last_name" required />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4">
                                                <div className="form-group">
                                                    <label className="text-md font-bold block mb-4">Mobile Phone *</label>
                                                    <input type="text" onChange={submitValues} value={data.phone} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="phone" placeholder="+XXX XXX XXX XXX" name="phone" required />
                                                    <span className="text-xs text-red-600">Number has to be between 10 and 13 characters</span>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4">
                                                <div className="form-group">
                                                    <label className="text-md font-bold block mb-4">Email *</label>
                                                    <input type="email" onChange={submitValues} value={data.email} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="email" placeholder="Enter Email" name="email" required />
                                                </div>
                                            </div>
                                            <div className="col-span-8">
                                                <div className="form-group">
                                                    <label className="text-md font-bold block mb-4">About *</label>
                                                    <textarea className="form-control mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" rows={5} onChange={submitValues} value={data.tell_us_more} id="tell_us_more" placeholder="Tell us more about your project" required name="tell_us_more" />
                                                </div>
                                            </div>
                                            <div className="col-span-8">
                                                <div className="form-group">
                                                    <label className="text-md font-bold block mb-4">What sort of assistance are you looking for *</label>
                                                    <textarea className="form-control mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" rows={5} onChange={submitValues} value={data.assistance} id="assistance" placeholder="Tell us how we can help push your project" required name="assistance" />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" id="submitBtn" className="bg-primary my-4 rounded hover:bg-blue-600 w-full px-4 py-2">Submit</button>
                                    </form>

                                    {status !== "" && <Alert status={status} message={message} />}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </>
    )
}

export default BsAccelerator