import React from 'react'
import { Link } from 'react-router-dom'

const Tenx = () => {
    return (
        <>
            <div>
                {/* Zero to Hero 10-X program */}
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
                        <div className="lg:w-1/2">
                            <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                                Zero to Hero 10x
                            </h2>
                        </div>
                        <div className="lg:w-1/2">
                            <p className="text-base text-gray-700 md:text-md">
                                This is a two month fast paced program that consists of one month of intense classroom sessions and one month of practical and experiential sessions where students are given seed money and are guided in the process of starting real companies which will be evaluated over the course of the month.</p>
                        </div>
                    </div>
                    <ul className="mt-8 md:grid md:grid-cols-2 gap-6">
                        <li className="mt-6 lg:mt-0">
                            <div className="flex">
                                <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span className="ml-4 text-base leading-6 font-medium text-black">
                                    21 Laws of Personal Development.
                                </span>
                            </div>
                        </li>
                        <li className="mt-6 lg:mt-0">
                            <div className="flex">
                                <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span className="ml-4 text-base leading-6 font-medium text-black ">
                                    The Money Course - The science and dynamics of money.
                                </span>
                            </div>
                        </li>
                        <li className="mt-6 lg:mt-0">
                            <div className="flex">
                                <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span className="ml-4 text-base leading-6 font-medium text-black ">
                                    Common Sense Structures to Keep you in Business: Admin, Book keeping, Human Resources.
                                </span>
                            </div>
                        </li>
                        <li className="mt-6 lg:mt-0">
                            <div className="flex">
                                <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span className="ml-4 text-base leading-6 font-medium text-black ">
                                    Passion to Profit - taking an idea from the brain to the bank.
                                </span>
                            </div>
                        </li>
                        <li className="mt-6 lg:mt-0">
                            <div className="flex">
                                <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span className="ml-4 text-base leading-6 font-medium text-black ">
                                    The Master Communicator - learning how to communicate in a persuasive, convincing and assertive manner.
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <section className="mb-6">
                    <div className="bg-black text-white py-8">
                        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
                            <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
                                <p className="ml-2 text-yellow-300 uppercase tracking-loose">
                                    The Zero to Hero 10X Program
                                </p>
                                <p className="text-md md:text-base text-gray-50 mb-4">
                                    Here’s your guide to the Zero to Hero 10X Program. Take the chance to understand process and how to be a part of this great adventuere.
                                </p>
                            </div>
                            <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
                                <div className="container mx-auto w-full h-full">
                                    <div className="relative wrap overflow-hidden p-4 sm:p-10 h-full">
                                        <div className="border-2-2 border-yellow-555 absolute h-full border" style={{ right: '50%', border: '2px solid FFC100', borderRadius: '1%' }} />
                                        <div className="border-2-2 border-yellow-555 absolute h-full border" style={{ right: '50%', border: '2px solid FFC100', borderRadius: '1%' }} />
                                        <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                                            <div className="order-1 w-5/12" />
                                            <div className="order-1 w-5/12 px-1 py-4 text-right">
                                                <p className="mb-3 text-base text-yellow-300">
                                                    Stage 1:
                                                </p>
                                                <h4 className="mb-3 font-bold text-lg md:text-2xl">
                                                    Registration
                                                </h4>
                                                <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                                                    Visit the registration page using the Join Us button and provide the requested information in the registration form.
                                                    You will get a notification of your acceptance into the program.
                                                    You will also get an onboarding video that will take you through the whole process as well as the timetable for your live webinars.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mb-8 flex justify-between items-center w-full right-timeline">
                                            <div className="order-1 w-5/12" />
                                            <div className="order-1  w-5/12 px-1 py-4 text-left">
                                                <p className="mb-3 text-base text-yellow-300">
                                                    Stage 2:
                                                </p>
                                                <h4 className="mb-3 font-bold text-lg md:text-2xl">
                                                    WEBINARS
                                                </h4>
                                                <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                                                You will be exposed to an intense month of mind renewing, personal development and general business education.
                                                This is a combination of live webinars and pre-recorded sessions from entrepreneurs and business leaders from different walks of life and with different experiences to share.                                                </p>
                                            </div>
                                        </div>
                                        <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                                            <div className="order-1 w-5/12" />
                                            <div className="order-1 w-5/12 px-1 py-4 text-right">
                                                <p className="mb-3 text-base text-yellow-300">
                                                    Stage 3:
                                                </p>
                                                <h4 className="mb-3 font-bold text-lg md:text-2xl">
                                                    PRACTICALS
                                                </h4>
                                                <p className="text-sm md:text-base leading-snug text-gray-50 text-left text-opacity-100">
                                                You will be grouped together in no particular order and will be given a very small amount of money to trade with. This is to test the following:
<ul className='text-left pt-3'>
    <li>- Your ability to translate what was learnt to practice</li>
    <li>- Your ability to work in a group</li>
    <li>- Your thought process</li>
    <li>-Your Leadership skills</li>

</ul>

                                                </p>
                                            </div>
                                        </div>
                                        <div className="mb-8 flex justify-between items-center w-full right-timeline">
                                            <div className="order-1 w-5/12" />
                                            <div className="order-1 w-5/12 px-1 py-4">
                                                <p className="mb-3 text-base text-yellow-300">
                                                    Stage 4:
                                                </p>
                                                <h4 className="mb-3 font-bold  text-lg md:text-2xl text-left">
                                                    SELECTION
                                                </h4>
                                                <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                                                You will then be grouped according to your interests.
                                                Those who want to go into media for instance are grouped together.
                                                Those who are interested in beauty are grouped together and so on and so forth.
                                                At this level you are now paired with mentors and instructors who will help you to understand the business side of your dream and also who will help you build targeted capacity for your areas of interest.
                                                You will also be paired with people who will help you set up the right structures and processes and help you with all the legalities of your business.
                                                </p>
                                            </div>
                                        </div>
                                        <img className="mx-auto -mt-28 md:-mt-36" loading="lazy" src="/thestreetuniversity_10x.png" alt="The Street University Zero to Hero 10X program" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
                        <div className="flex flex-col justify-center">
                            <div className="max-w-xl mb-6">
                                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                                    The criteria
                                </h2>
                                <p className="text-base text-gray-700 md:text-lg">
                                    Scholarships, grants and payment plans available for students who meet the criteria.</p>

                            </div>
                            <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
                                <div className="bg-white">
                              
                                    <Link to="/register/ten-x" className="inline-block px-5 py-3 mt-8 text-lg font-medium text-white bg-blue-500 rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline">
                                        Join us</Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img className="object-cover w-full h-56 rounded shadow-lg sm:h-96"  loading="lazy" src="/thestreetuniversity_joygroup.jpg" alt="The Street University" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tenx