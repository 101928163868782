import React from 'react'
import NotFoundComponent from '../components/NotFoundComponent'

const NotFound = () => {
  return (
    <>
      <NotFoundComponent />
    </>
  )
}

export default NotFound