import React, {memo, useState} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { testimonial_data } from './testimonial_data';

const Testimonials = () => {

  const [view, setView] = useState(false);

  return (
    <>
      <div className="max-w-xl md:mx-auto sm:text-center my-8 sm:my-10 lg:max-w-2xl">
        <h4 className="inline-block px-3 py-px mb-4 text-md font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
          Word on the Street
        </h4>
      </div>
      <div className="flex items-center justify-center px-5 py-5">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          // onSlideChange={() => console.log("The street University")}
          onSwiper={(swiper) => console.log(swiper)}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          rewind= {true}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          className="mySwiper"
          modules={[Pagination, Autoplay]}
          >
      {testimonial_data &&
    testimonial_data.map((testimonial, index) => (
        <SwiperSlide key={index}>
        <div className="w-full mx-auto max-w-4xl rounded-lg bg-white shadow-lg px-5 pt-5 pb-10 text-gray-800">
          <div className="w-full mb-10">
            <div className="text-3xl text-indigo-500 text-left leading-tight h-3">
              “
            </div>
            <p className="text-sm text-gray-600 text-center px-5">
              {testimonial?.message}
              <span className="text-green-500 ml-1" onClick={() => setView(!view)}>more...</span>
              <span className={`${view ? "block" : "hidden"}`}>
               {testimonial?.extra}
              </span>
            </p>
            <div className="text-3xl text-indigo-500 text-right leading-tight h-3 -mt-3">
              ”
            </div>
          </div>
          <div className="w-full">
            <p className="text-md text-indigo-500 font-bold text-center">
              {testimonial?.name}
            </p>
          </div>
        </div>
      </SwiperSlide>
    ))
}


        </Swiper>
        <div className="text-center mt-4 top-swiper-pagination"></div>

      </div>
    </>
  )
}

export default memo(Testimonials)