import React from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
// import HighStreet from '../components/Programs/HighStreet';
import StreetMba from '../components/Programs/StreetMba';
import Tenx from '../components/Programs/Tenx';

const Programs = () => {
  const { program } = useParams();
  // const programs = ["ten-x", "high-street", "street-mba"];

  return (
    <>
      {
        <>
          {/* {
            program === 'high-street' && (
              <HighStreet />)
          } */}

          {
            program === 'ten-x' && (
              <Tenx />)
          }

          {
            program === 'street-mba' && (
              <StreetMba />)
          }
        </>
      }
      {
        (program === '') && (
          <section className="px-4 pb-24 sm:py-24 mx-auto max-w-7xl">
            <div className="w-full mx-auto text-center lg:w-2/3">
              <h1 className="mb-4 text-6xl font-thin text-gray-900">404</h1>
              <p className="mb-3 text-xl font-bold text-gray-900 md:text-2xl">Oh no! We couldn’t find the page you were looking for.</p>
              <p className="mb-3 text-lg font-medium text-gray-700">
                <Link to="/" className="no-underline">Go back to the homepage.</Link>
              </p>
            </div>
          </section>
        )
      }
    </>
  )
}

export default Programs