import React from "react";
// import { Tab } from '@headlessui/react'

// import TenxForm from "./TenxForm";
import TenxSelfSponsorshipForm from "./TenxSelfSponsorshipForm";

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

const TenX = () => {

    return (
        <>
            <div className="px-4 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">
                <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
                    <div className="flex">
                        <div className="pt-6 pb-8">
                            <h2 className="text-3xl font-extrabold text-black sm:leading-[3.5rem] sm:text-4xl">
                                <span className="block">
                                    Ready to be part of the Zero to Hero 10X challenge?
                                </span>
                                <span className="block text-indigo-500">
                                    It's now or never.
                                </span>
                            </h2>
                            <p className="text-base text-black md:text-md mt-4 sm:mt-8">
                                This is a fast paced program that consists of intense practical and experimental classroom sessions where students are given seed money and are guided in the process of starting real companies which will be evaluated over the course of the program.</p>
                        </div>
                    </div>

                    <div className="relative text-black">
                        <div className="card form-card px-3 sm:px-8">
                            {/* <Tab.Group> */}

                                {/* <Tab.List className="flex justify-center mt-20 mx-10">
                                    <Tab
                                        className={({ selected }) =>
                                            classNames(
                                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-black',
                                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-black focus:outline-none focus:ring-2',
                                                selected
                                                    ? 'bg-blue-400 shadow'
                                                    : 'text-green-700 hover:bg-white/[0.12] hover:text-red-600'
                                            )
                                        }
                                    >
                                        Seeking Sponsorship
                                    </Tab>
                                    <Tab
                                        className={({ selected }) =>
                                            classNames(
                                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-black',
                                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-black focus:outline-none focus:ring-2',
                                                selected
                                                    ? 'bg-blue-400 shadow'
                                                    : 'text-green-700 hover:bg-white/[0.12] hover:text-red-600'
                                            )
                                        }
                                    >
                                        Self Sponsored
                                    </Tab>
                                </Tab.List> */}
                                {/* <Tab.Panels>
                                    <Tab.Panel> */}
                                        {/* <div className="card-body">
                                        <p className='mt-4 mb-16'>For individuals who would like to be sponsored for the program. Please visit the link below and fill in your details.</p>

                                       <a href="https://forms.gle/uGsFLFpkLLjZTMTu9" rel="noreferrer" target="_blank" title="Seeking sponsorship" className="rounded text-white mt-4 btn p-4 bg-black font-semibold text-md hover:no-underline hover:text-white hover:bg-green-700 hover:-translate-y-1 hover:scale-110 duration-300">Zero to Hero Registration Link</a>

                                        <div id="processing" />
                                        <div id="msg"> </div>
                                    </div> */}
                                    {/* </Tab.Panel>
                                    <Tab.Panel> */}
                                        <div className="card-body">
                                        {/* <p className='mt-4'>For individuals who would like to sponsor themselves for the program.</p> */}
 <TenxSelfSponsorshipForm />
                                        <div id="processing" />
                                        <div id="msg"> </div>
                                    </div>
                                    {/* </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TenX


