import React from 'react'
import { Link } from 'react-router-dom'
import MidSection from '../components/Sponsor/MidSection'
import SuccessStories from '../components/Sponsor/SuccessStories'
const Sponsor = () => {
    return (
        <>
            <div className="max-w-screen-xl p-4 bg-white mx-auto px-4 sm:px-6 lg:px-8 relative py-26">
                <div className="relative">
                    <div className="lg:grid  lg:grid-cols-2 lg:gap-8 lg:items-center">
                        <div className="lg:col-start-1 ml-auto">
                            <p className="text-base leading-6 text-indigo-500 animate-pulse font-semibold uppercase">
                                To Our Sponsors
                            </p>
                            <h4 className="mt-2 text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                                We are transforming society through entrepreneurship.
                            </h4>
                            <p className="mt-4 text-lg leading-6 text-gray-500">
                                Don't be left behind
                            </p>
                            <div>
                                <Link
                                    to="/register/ten-x"
                                    className="my-4 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-black hover:bg-gray-700 hover:no-underline focus:shadow-outline focus:outline-none"
                                >
                                    Sponsor students
                                </Link>
                            </div>
                            <ul className="mt-8 md:grid md:grid-cols-3 gap-6">
                                <li className="mt-6 lg:mt-0">
                                    <div className="flex">
                                        <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                            <svg
                                                className="h-4 w-4"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="ml-4 text-base leading-6 font-medium text-blue-800">
                                            WE TRAIN
                                        </span>
                                    </div>
                                </li>
                                <li className="mt-6 lg:mt-0">
                                    <div className="flex">
                                        <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                            <svg
                                                className="h-4 w-4"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="ml-4 text-base leading-6 font-medium text-blue-800">
                                            WE FUND
                                        </span>
                                    </div>
                                </li>
                                <li className="mt-6 lg:mt-0">
                                    <div className="flex">
                                        <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                            <svg
                                                className="h-4 w-4"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="ml-4 text-base leading-6 font-medium text-blue-800">
                                            WE LAUNCH
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="mt-10 lg:-mx-4 relative lg:mt-0 lg:col-start-2">
                            <div className="relative space-y-4 sm:ml-20">
                                <div className="flex items-end justify-center lg:justify-start space-x-4">
                                    <img
                                        className="rounded-lg shadow-lg w-32 md:w-60"
                                        width={200}
                                        loading="lazy"
                                        src="https://images.ctfassets.net/2aefkrtkr5cr/kwHHk3i5eK4uwtCzcPm59/b969bcceaafc83b1446438e9675eb5fd/thestreetuniversity_new2.jpg"
                                        alt="The Street University Courses"
                                    />
                                    <img
                                        className="rounded-lg shadow-lg w-40 md:w-72"
                                        width={260}
                                        loading="lazy"
                                        src="https://images.ctfassets.net/2aefkrtkr5cr/5WhtqrQMed7iDjYRmvAHzw/594d9fd4eb1e744b99c2b2e16ab66666/thestreetuniversity_new1.jpg"
                                        alt="The Street University Courses"
                                    />
                                </div>
                                <div className="flex items-start justify-center lg:justify-start space-x-4 ml-12">
                                    <img
                                        className="rounded-lg shadow-lg w-24 md:w-48"
                                        width={170}
                                        loading="lazy"
                                        src="https://images.ctfassets.net/2aefkrtkr5cr/A1cMkQV5dkQT74AktGaHN/0dd1541b24df9aebe88dacaa499310e9/thestreetuniversity_new3.jpg"
                                        alt="The Street University Courses"
                                    />
                                    <img
                                        className="rounded-lg shadow-lg w-32 md:w-60"
                                        width={200}
                                        loading="lazy"
                                        src="https://images.ctfassets.net/2aefkrtkr5cr/1QnSHAQPylF4HGWPjPjKKV/07f9cefa8c1b2a00c3cb5c0e9fa7c59e/thestreetuniversity_new4.jpg"
                                        alt="The Street University Courses"
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                {/* Success Stories */}
                <MidSection />
                <SuccessStories />
            </div>

        </>
    )
}

export default Sponsor