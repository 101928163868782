import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { useParams } from 'react-router-dom';
import apiClient from '../services/api';
// import Spinner from './Spinner/Spinner';
import Spinner from '../components/Spinner';
import Alert from "../components/Alert";


import { Link } from 'react-router-dom';

const Payment = () => {
  const { paymentcode } = useParams();
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const [program_data, setProgramData] = useState({
    public_key: "",
    amount: "",
    description: "",
    title: "",
    payment_link: ""
  });
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('');


  const [user_data, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });
  const [message, setMessage] = useState('');
  const [messag, setMessag] = useState('');
  const [program, setProgram] = useState('');

  const [status, setStatus] = useState("");
  useEffect(() => {
    apiClient.get(`/verifypaymentcode/${paymentcode}`).then((response) => {
      if (response.status === 200 && response.data.status === "success") {
        if (response.data.user && response.data.program) {
          setUserData(...response.data.user)
          setProgram(response.data.program);
          switch (program) {
            case 'ten-x':
              setStatus('success');
              setProgramData({
                public_key: "FLWPUBK-827dd0150ad2c9e1109bf10b675676d8-X",
                amount: 300,
                description: "Payment for the Zero to Hero 10-X Program",
                title: "ZERO TO HERO 10X",
                payment_link: "!#"
              });
              break;

            case 'street-mba':
              setStatus('success');
              setProgramData({
                public_key: "FLWPUBK-827dd0150ad2c9e1109bf10b675676d8-X",
                amount: 1000,
                description: "Payment for the Street MBA Program",
                title: "STREET MBA",
                payment_link: "!#"
              });
              break;

            case 'high-street':
              setStatus('success');
              setProgramData({
                public_key: "FLWPUBK-20f45c12c74f94464ac20f70ceb13cfb-X",
                amount: 10,
                description: "Payment for the TSU High Street Program",
                title: "TSU HIGH STREET",
                payment_link: "https://flutterwave.com/pay/brnjqe7hwv28"
              });
              break;

            default:
              // setStatus('error');
              break;
          }
        }
      }
      else if (response.data.status === "info") {
        setStatus('info');
        setProgram(response.data.program);
        setMessage(response.data.message);
      }
      else {
        setStatus('error');
      }
      setIsLoading(false);
    });
  }, [paymentcode, program]);

  const config = {
    public_key: program_data.public_key,
    tx_ref: Date.now(),
    amount: program_data.amount * 100,
    currency: 'KES',
    payment_options: 'card,mobilemoney,ussd,mpesa',
    customer: {
      email: user_data.email,
      phonenumber: user_data.phone,
      name: `${user_data.first_name} ${user_data.last_name}`,
    },
    customizations: {
      title: program_data.title,
      description: program_data.description,
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  const fwConfig = {
    ...config,
    text: 'Pay with Flutterwave!',
    callback: (response) => {
      console.log(response);
      closePaymentModal() // this will close the modal programmatically
    },
    onClose: () => { },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    apiClient.get(`/getPaymentLink/${program}/${email}`).then((response) => {
      if (response.status === 200 && response.data.status === "success") {
        setStatus(response.data.status);
        window.location.pathname = "/registration";
        setMessag(response.data.message);
      }
      else {
        setStatus('error');
        setMessag('Technical error occured, please contact the system administrator')
      }
      setIsLoading(false);
      setTimeout(() => {
        setEmail("")
        setStatus("");
        setMessag("");
      }, 3000);
    });
  }
  return (
    <>
      <section>
        {isLoading ? (
          <div className="my-40">

            <Spinner />
          </div>
        ) : (
          <>
            {
              status === 'success' &&

        <div>
          {messag !== "" && <Alert status={status} message={messag} />}
          <div className="my-4 sm:my-20 sm:my-36 mx-4">
            <h2 className="max-w-lg sm:text-center mb-6 font-sans text-2xl sm:text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-3xl mx-auto">
              Select your preferred payment method to complete your payment
            </h2>
            <div className="relative sm:px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 tab-pane fade in active">
              <div className="max-w-xl mb-10 mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <p className="text-sm sm:text-xl text-black font-bold md:text-lg">
                  {program_data?.title} PROGRAM FEE:</p>
                <strong className="text-green-600 text-sm sm:text-xl font-bold">{`$${program_data.amount}.00`}</strong>
              </div>
              <div className="grid max-w-md gap-10 row-gap-8 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto">
                <div className="flex flex-col transition duration-300 bg-white border border-black rounded shadow-sm hover:shadow">
                  <div className="relative w-full p-4 h-40 md:h-36">
                    <img src="/debit.png" className="object-cover rounded-t" alt="Plan" />
                  </div>
                  <div className="flex flex-col justify-between flex-grow p-4 sm:p-8 border border-t-0 rounded-b">
                    <a href={program_data?.payment_link} target="_blank" rel="noreferrer" className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-green-600 hover:bg-black focus:shadow-outline focus:outline-none">
                      Debit / Credit Card
                    </a>
                  </div>
                </div>
                <div className="flex flex-col transition duration-300 bg-white border border-black rounded shadow-sm hover:shadow">
                  <div className="relative w-full p-4 h-40 md:h-36">
                    <img src="/mpesa.png" className="object-cover rounded-t" alt="Plan" />
                  </div>
                  <div className="flex flex-col justify-between flex-grow p-4 sm:p-8 border border-t-0 rounded-b">
                    <FlutterWaveButton className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-green-600 hover:bg-black focus:shadow-outline focus:outline-none" {...fwConfig} />
                  </div>
                </div>
                <div className="flex flex-col transition duration-300 bg-white border border-black rounded shadow-sm hover:shadow">
                  <div className="relative w-full p-4 h-40 md:h-36">
                    <img src="/paypall.png" className="object-cover rounded-t" alt="The Street University Payment Plan" />
                  </div>
                  <div className="flex flex-col justify-between flex-grow p-4 sm:p-8 border border-t-0 rounded-b">
                    <a rel="noreferrer" target='_blank' href={program_data?.payment_link} className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-green-600 hover:bg-black focus:shadow-outline focus:outline-none">
                      PayPal</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

            {
              (status === 'error' || status === 'info') && <>
                <section className="my-20 home-section bg-white">
                  <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
                      <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
                        <div>
                          {
                            status === 'info' &&
                            <>
                              <span className="bg-purple-600 mt-12 mb-6 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition hover:no-underline duration-200 rounded shadow-md hover:bg-red-800 focus:shadow-outline focus:outline-none">
                                {message}
                              </span>
                              <div className="inset-0 flex items-center justify-center">
                                <button
                                  type="button"
                                  onClick={openModal}
                                  className="rounded-md bg-black px-4 mt-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                >
                                  Open form
                                </button>
                                <Transition appear show={isOpen} as={Fragment}>
                                  <Dialog as="div" className="relative z-10" onClose={closeModal}>
                                    <Transition.Child
                                      as={Fragment}
                                      enter="ease-out duration-300"
                                      enterFrom="opacity-0"
                                      enterTo="opacity-100"
                                      leave="ease-in duration-200"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                                    </Transition.Child>

                                    <div className="fixed inset-0 overflow-y-auto">
                                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                                        <Transition.Child
                                          as={Fragment}
                                          enter="ease-out duration-300"
                                          enterFrom="opacity-0 scale-95"
                                          enterTo="opacity-100 scale-100"
                                          leave="ease-in duration-200"
                                          leaveFrom="opacity-100 scale-100"
                                          leaveTo="opacity-0 scale-95"
                                        >
                                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                            <Dialog.Title
                                              as="h3"
                                              className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                              Please enter your registration email address
                                            </Dialog.Title>
                                            <form className="text-sm text-gray-500" method="POST" action="" onSubmit={handleSubmit}>
                                              <div className="mt-2">
                                                <div className="col-span-8 sm:col-span-4">
                                                  <div className="form-group">
                                                    <label className="text-md font-bold block mb-4">Email *</label>
                                                    <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="email" placeholder="email" name="email" required />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="mt-4">
                                                <button
                                                  type="submit"
                                                  className="inline-flex justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                  onClick={closeModal}
                                                >
                                                  Got it, thanks!
                                                </button>
                                              </div>
                                            </form>
                                          </Dialog.Panel>
                                        </Transition.Child>
                                      </div>
                                    </div>
                                  </Dialog>
                                </Transition>
                              </div>
                            </>
                          }
                          {
                            status === 'error' &&
                            <>
                              <Link to="/" className="my-20 mt-12 py-8 sm:py-0 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition hover:no-underline duration-200 rounded shadow-md bg-red-500 hover:bg-red-800 focus:shadow-outline focus:outline-none">
                                Technical Error, occurred please return to home page
                              </Link>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            }
          </>)}
      </section>
    </>
  )
}

export default Payment