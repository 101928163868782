export const testimonial_data = [
    {
        id: 1,
        name: "DORCAS KEYA",
        message: "It has been revolutionary. I never thought business was my thing but after 8 weeks of going through the Street MBA, I now look at everything from a business point of view. Now, I look at my OB (Organizational Behavior???) and I'm thinking of how I can make money from it. I now realize that I do not need money to start a business. Instead what I need are relationships. This has been",
        extra: "very revolutionary for me - that the greatest asset I have are my relationships! I have learnt to stop making projections based on only assumptions. I never knew how I could get money for my vision but now things are becoming clearer and so I am ready to do my business plan and put it forward for scrutiny."
    },
    {
        id: 2,
        name: "JOHN SIMIYU",
        message: "I have learnt personal discipline. The fact that I had no one looking over me and had to take my personal initiative for my personal growth has been major. I had to learn to ignore distractions and make sure I never missed my classes. Secondly, the topics were very good for me and I learnt a lot. The lecturers were first class and had deep insights that have opened my eyes teaching me",
        extra: "things I never even knew or considered. Many of the topics like NFTs, Family business and the story of the Solar business have opened my eyes to business in a whole new way.Our project as a team has also taught me a lot and now I am looking at how to scale that particular business using all the skills I have learnt. I have learnt the power of commitment from my team. Despite the diversity and the fact that we were in different parts of the world, we were still able to start and run a business using the skills we had been taught in a very short period of time."
    },
    {
        id: 3,
        name: "EDNA SUDI",
        message: "It has been 8 weeks of commitment. I have learnt discipline, commitment and the importance of being a team player. We gelled together as a team. We met often and everytime single time we would look at our business and come up with something new to take the business further. This was in spite of us being in various locations. Two sessions stood out for me.",
        extra: "The first one was the class where we learnt the importance of having a board and that a board must have an odd number of members and not an even number of members. It has been 8 weeks of commitment. I have learnt discipline, commitment and the importance of being a team player. We gelled together as a team. We met often and everytime single time we would look at our business and come up with something new to take the business further. This was in spite of us being in various locations. Two sessions stood out for me."
    },
    {
        id: 4,
        name: " KARLENE WILLIAMS SEARS",
        message: `It has been transformational for me. I have been transformed as a leader and this happened for me when we were put in teams and got the opportunity to implement the things we had been taught.
        In short, I learnt more when collaboration took place. I have learnt that there is no perfect time to start a business.
        I learnt to think differently and understood that the possibilities are limitless`,
        extra: `once you begin to think, “How would we do this business if our lives depended on it?”
        I am now excited at the possibility of a global business because I can see that it can be done regardless of location.
        I also learnt the difference between the academic (the things we learnt in the class) and the practicals (the things we now had to do when we had to start our own business).`
    },
    {
        id: 5,
        name: "MUMBI NYARUIYA",
        message: ` I learnt to step out of my comfort zone. I learnt that everyone has different strengths and that when we bring our strengths together, great things can happen.
        I learnt the power of relationships in business and that if all I have are relationships I can leverage this.
        I also learnt the difference between what you learn in class and how it works when you step out to use what you learnt practically.`,
        extra: ``
    },
    {
        id: 6,
        name: "SALAMATU TAMBA",
        message: ` I never knew I could think like a business woman and this came into play when we were put into different teams.
        I then got the opportunity to practice what I had learnt during the classes and this has gone a long way in preparing me for when I step out and start my own business.
        I learnt that when you buy something, you need to multiply it by 2 or 2 and a half times`,
        extra: `and I never knew this.
        Also, the fact that we had to think about the business with the mindset of “What if my life depended on this?”  was life-changing`
    },
    {
        id: 7,
        name: "VICTORIA ADEMBESA",
        message: `The practical side was my highlight. Having to come up with a company and having to understand the difference between goals and strategy.
        The sessions were rich. Like Edna, the sessions Boards was a major eye-opener for me and what stood out was all the technicalities involved in choosing a board.
        This was so worthwhile!!!
        Also, I had to deal with fear of rejection when selling`,
        extra: `when my team started their business`
    },
    {
        id: 8,
        name: "GERTRUDE AMISI",
        message: ` What stood out for me throughout the entire program was that I cannot run a business based on assumptions. I also learnt about building relationships in business and using your networks.
        I particularly enjoyed the session on Family Businesses and learnt the need to be professional in a family business.
        In the beginning, I did not know anyone in my team but through our interactions,`,
        extra: `they have become my friends and I have learnt a lot.
        After every meeting, I would actually go and think about what we discussed. Finally, I learnt that when you want to start a business, don’t overthink it.`
    }
];