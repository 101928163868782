import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Register from "./pages/Authentication/Register";
import Landing from "./pages/Landing";
import NotFound from "./pages/NotFound";
// import Blog from "./pages/Blog/Blog";
// import CoursesCategories from "./pages/Course/CoursesCategories";
// import SingleCategory from "./pages/Course/SingleCategory";
// import Single from "./pages/Blog/Single";
// import Categories from "./pages/Blog/Categories";
// import MediumBlog from "./pages/MediumBlog";
// import Events from "./pages/Events";
import Programs from "./pages/Programs";
import Services from "./pages/Services";
import Confirmation from "./pages/Confirmation";
import Payment from "./pages/Payment";
// import Paymentb from "./pages/Paymentb";
import ScrollToTop from "./components/ScrollToTop";
import Sponsor from "./pages/Sponsor";

const App = () => {
  return (
    <>
      <Header />
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/register/:program" element={<Register />} />
        <Route path="/programs/:program" element={<Programs />} />
        <Route
          path="/services/:service"
          element={<Services />}
        />
        {/* <Route path="/blog" element={<Blog />} /> */}
        {/* <Route path="/mediumblog" element={<MediumBlog />} /> */}
        {/* <Route path="/events" element={<Events />} /> */}
        {/* <Route path="/blog/topics" element={<Categories />} /> */}
        {/* <Route path="/blog/:slug" element={<Single />} /> */}
        {/* <Route path="/courses/categories" element={<CoursesCategories />} /> */}
        <Route path="/confirmation/:program" element={<Confirmation />} />
        {/* <Route path="/courses/categories/:slug" element={<SingleCategory />} /> */}

        {/* <Route path="/view/writer/:username" element={<ViewWriter />} />
            <Route path="/view/post/:id" element={<ViewPost />} />
            <Route path="/edit/post/:id" element={<EditPost />} />
            <Route path="/add/post" element={<AddPost />} /> */}

        <Route path="/payment/:paymentcode" element={<Payment />} />
        <Route path="/sponsor" element={<Sponsor/>}/>
        {/* <Route path="/paymentb" element={<Paymentb />} /> */}
        <Route path="/404" element={<NotFound />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
