import React from 'react'

const MidSection = () => {
    return (
        <>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
                    <div className="lg:w-1/2">
                        <h2 className="max-w-md sm:mt-10 font-sans text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                            How It Works
                        </h2>
                    </div>
                    <div className="lg:w-1/2">
                        <p className="text-base text-gray-700 md:text-md">
                            <ul className="mt-8 md:grid md:grid-cols-2 gap-6">
                                <li className="mt-6 lg:mt-0">
                                    <div className="flex">
                                        <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                            <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                                </path>
                                            </svg>
                                        </span>
                                        <span className="ml-4 text-base leading-6 font-medium text-black">
                                            Online Registration
                                        </span>
                                    </div>
                                    <p className='pt-4'>Students register their details on our website.</p>
                                </li>
                                <li className="mt-6 lg:mt-0">
                                    <div className="flex">
                                        <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                                            <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                                </path>
                                            </svg>
                                        </span>
                                        <span className="ml-4 text-base leading-6 font-medium text-black">
                                            APPROVAL
                                        </span>
                                    </div>
                                    <p className='pt-4'>Candidates are approved against our selection criteria.</p>
                                </li>
                            </ul></p>
                            </div>
                </div>

                <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
                    <div className="lg:w-1/2">
                        <h2 className="max-w-md sm:mt-10 font-sans text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                            How It Runs
                        </h2>
                    </div>
                    <div className="lg:w-1/2">
                        <ul className="space-y-3 mt-4">
                            <li className="flex items-start text-black">
                                <span className="mr-1">
                                    <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                        <polygon strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                                    </svg>
                                </span>
                                Students access classes online accessible on any device
                            </li>
                            <li className="flex items-start text-black">
                                <span className="mr-1">
                                    <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                        <polygon strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                                    </svg>
                                </span>
                                Students are given assignments
                            </li>
                            <li className="flex items-start text-black">
                                <span className="mr-1">
                                    <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                        <polygon strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                                    </svg>
                                </span>
                                Students have access to our Zero to Hero community where they can interact with other students.
                            </li>
                            <li className="flex items-start text-black">
                                <span className="mr-1">
                                    <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                        <polygon strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                                    </svg>
                                </span>
                                Students work in groups for the <span className='text-black font-bold ml-1'>practical session.</span></li>
                        </ul>
                    </div>
                </div>

                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
                        <div className="flex flex-col justify-center">
                            <div className="max-w-xl mb-6">
                                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                                    THE PRACTICAL SESSIONS
                                </h2>
                                <p className="text-base text-gray-700 md:text-md">
                                    These are sessions where the students are put into small groups.
                                    This exercise has proved to be one of the most powerful in helping  the students to put to practice what they have learnt.
                                    Every week they share their progress reports and their course supervisors are able to help them to rethink and fine tune their strategies.</p>
                            </div>
                        </div>
                        <div>
                            <img className="object-cover w-full h-56 rounded shadow-lg sm:h-96" loading="lazy" src="https://images.ctfassets.net/2aefkrtkr5cr/2TC6vE3LxgxfHOI92sSjQh/39bda7e90b324d858a446d3b662ffdbb/thestreetuniversity_joygroup.jpg" alt="The Street University" />
                        </div>
                    </div>
                </div>



                <div className="px-4 py-16 mx-auto md:px-16 lg:px-8 lg:py-20">
                    <div className="grid gap-8 lg:grid-cols-2 sm:mx-auto">
                        <div className="flex flex-col justify-center">
                            <h2 className="animate-bounce max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl">
                                What Happens Next
                            </h2>
                            <span className="text-base text-gray-700 md:text-md">
                                After the completion of the courses the students will become 10x alumni and technically this marks the end of the first phase of the program for them.
                                If they chose to, they can now progress to the post-grad phase of the program.
                                The post Grad phase is where The Street University enters into a business arrangement with willing students.
                                This arrangement covers the following:</span>
                            <ul className="space-y-3 mt-4">
                                <li className="flex items-start text-black">
                                    <span className="mr-1">
                                        <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                            <polygon strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                                        </svg>
                                    </span>
                                    Help the students to set up their companies
                                </li>
                                <li className="flex items-start text-black">
                                    <span className="mr-1">
                                        <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                            <polygon strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                                        </svg>
                                    </span>
                                    Provide technical and consultancy services for the company which will include advisory services in product design, branding, route to market, and even helping them to put the right teams together to drive the vision that they have.</li>
                                <li className="flex items-start text-black">
                                    <span className="mr-1">
                                        <svg className="w-5 h-5 mt-px text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                                            <polygon strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                                        </svg>
                                    </span>
                                    We will also expose them to a pool of professionals in the areas of accounts, tax, legal, etc. who will give those services at a specially negotiated Street University rate.</li>
                            </ul>

                            <span className='mt-2'>
                                This will ensure the sustainability of the Street University and will get the program to a place where it does not need external funding because through revenue generated by the companies started with students, the program will be able to fund itself.
                            </span>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <img className="object-cover w-full h-96 col-span-2 rounded shadow-lg" loading="lazy" src="/streetuni-what-happens-next.jpg" alt="The street University learning" />
                            <img className="object-cover w-full h-56 rounded shadow-lg" loading="lazy" src="/streetuni-next1.jpg" alt="The street University learning" />
                            <img className="object-cover w-full h-56 rounded shadow-lg" loading="lazy" src="/streetuni-next2.jpg" alt="The street University learning" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MidSection