import axios from 'axios';

const getToken = ()=>{
  if(localStorage.getItem('token')){
      return localStorage.getItem('token')
  }
}

// const prod_url = 'https://api.thestreetuniversity.com/api';
const prod_url = 'https://orca-app-cgtgm.ondigitalocean.app/api';
// const development_url = 'http://127.0.0.1:8000/api';
const apiClient = axios.create({
  // baseURL: development_url,
  baseURL: prod_url,
    headers: { Authorization: `Bearer ${getToken()}`}

});
export default apiClient;