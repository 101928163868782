import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Tab } from '@headlessui/react'

import { Link } from 'react-router-dom';
import TenX from '../../components/Registration/TenX';
// import HighStreet from '../../components/Registration/HighStreet';
import BsAccelerator from '../../components/Registration/BsAccelerator';
import StreetMba from '../../components/Registration/StreetMba';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Register = () => {
  const { program } = useParams();
  const programs = ["ten-x", "street-mba", "business-accelerator"];
  // const programs = ["ten-x", "high-street", "street-mba", "business-accelerator"];



  const [val] = useState(programs.indexOf(program));

  return (
    <>
      <div className="my-8">
        <h2 className="max-w-lg sm:text-center mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl mx-auto">
          Choose your street
        </h2>
        <Tab.Group defaultIndex={val}>

          <Tab.List className="flex justify-center mt-20 mx-2">
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-black',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-black focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-green-600 shadow'
                    : 'text-blue-700 hover:bg-white/[0.12] hover:text-blue-500'
                )
              }
            >
              Zero to Hero 10X
            </Tab>
            {/* <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 px-2 sm:px-0 text-sm font-medium leading-5 text-black',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-black focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-green-600 shadow'
                    : 'text-blue-700 hover:bg-white/[0.12] hover:text-blue-500'
                )
              }
            >
              TSU High Street
            </Tab> */}
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 px-2 sm:px-0 text-sm font-medium leading-5 text-black',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-black focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-green-600 shadow'
                    : 'text-blue-700 hover:bg-white/[0.12] hover:text-blue-500'
                )
              }
            >
              Street MBA
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-black',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-black focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-green-600 shadow'
                    : 'text-blue-700 hover:bg-white/[0.12] hover:text-blue-500'
                )
              }
            >
              Business Accelerator
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel><TenX /></Tab.Panel>
            {/* <Tab.Panel><HighStreet /></Tab.Panel> */}
            <Tab.Panel><StreetMba /></Tab.Panel>
            <Tab.Panel><BsAccelerator /></Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

      </div>
      {
        (program === '' || !programs.includes(program)) && (
          <section className="px-4 pb-24 sm:py-24 mx-auto max-w-7xl">
            <div className="w-full mx-auto text-center lg:w-2/3">
              <h1 className="mb-4 text-6xl font-thin text-gray-900">404</h1>
              <p className="mb-3 text-xl font-bold text-gray-900 md:text-2xl">Oh no! We couldn’t find the page you were looking for.</p>
              <p className="mb-3 text-lg font-medium text-gray-700">
                <Link to="/" className="no-underline">Go back to the homepage.</Link>
              </p>
            </div>
          </section>
        )
      }
    </>
  )
}

export default Register