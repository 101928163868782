import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { Fragment } from 'react'
import { Menu, Transition, Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
<div className="px-4 sticky z-50 bg-white top-0 py-5 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-8">
  <div className="relative flex items-center justify-between">
    <Link to="/"  aria-label="The Street University" title="The Street University" className="inline-flex items-center">
    <img className="logo" src="/logo.png" alt="The Street University" />
    </Link>
    <ul className="flex items-center hidden space-x-8 lg:flex">
      {/* <li>
        <Link to="/"  aria-label="About Us" title="About Us" className="font-medium tracking-wide text-sm text-black transition-colors duration-200 hover:text-green-500">
          ABOUT US
        </Link>
      </li> */}
         {/* <li>
        <Link to="/sponsor"  aria-label="Sponsor" title="Sponsor" className="font-medium tracking-wide text-sm text-black transition-colors duration-200 hover:text-green-500">
          SPONSOR
        </Link>
      </li> */}
      <li>
        <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full py-2 bg-white text-sm font-medium text-gray-700 hover:text-green-500">
        PROGRAMS
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link to="/programs/ten-x"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Zero to Hero 10X
                </Link>
              )}
            </Menu.Item>
            {/* <Menu.Item>
              {({ active }) => (
                <Link to="/programs/high-street"

                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  TSU High Street
                </Link>
              )}
            </Menu.Item> */}
            <Menu.Item>
              {({ active }) => (
                <Link to="/programs/street-mba"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  The Street MBA
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
      </li>
      <li>
      <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full py-2 bg-white text-sm font-medium text-gray-700 hover:text-green-500">
        SERVICES
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link to="/services/business-accelerator"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Business Accelerator
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>

      </li>
      {/* <li>
        <Link to="/courses/categories"  aria-label="Course Categories" title="Course Categories" className="font-medium tracking-wide text-sm text-black transition-colors duration-200 hover:text-green-500">
          CATEGORIES
        </Link>
      </li>
      <li>
        <Link to="/events"  aria-label="About us" title="About us" className="font-medium tracking-wide text-sm text-black transition-colors duration-200 hover:text-green-500">
          EVENTS
        </Link>
      </li> */}
      <li>
        <a href="https://learn.thestreetuniversity.com/users/sign_in" target="_blank" rel="noopener noreferrer" className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-sm text-black transition duration-200 rounded shadow-md bg-white border border-green-600 hover:bg-green-700 focus:shadow-outline focus:outline-none" aria-label="Sign In" title="Sign In">
          SIGN IN
        </a>
      </li>
    </ul>
    <div className="lg:hidden">
      <button aria-label="Open Menu" title="Open Menu" className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50" onClick={() => setIsMenuOpen(true)}>

        <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
          <path fill="currentColor" d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z" />
          <path fill="currentColor" d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z" />
          <path fill="currentColor" d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z" />
        </svg>
      </button>
      {isMenuOpen && (
       <nav
       className="flex fixed w-full items-center justify-between px-6 h-16 bg-white text-gray-700 border-b border-gray-200 z-10 transition ease-in-out delay-150 duration-300"
       >
    <div
           className="z-10 fixed inset-0 transition-opacity"
         >
           <div
           onClick={() => setIsMenuOpen(false)} className="absolute inset-0 bg-black opacity-50"
             tabIndex="0"
           ></div>


<aside
         className={`transform top-0 py-6 left-0 w-64 bg-white font-bold text-black fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${isMenuOpen ? "translate-x-0" : "-translate-x-full"}`}
       >
         <div
        className="flex items-center justify-between p-4 hover:text-green-500" onClick={() => setIsMenuOpen(false)}
           >
           <Link to="/"  aria-label="The Street University" className="hover:text-green-500">Home</Link>
           <button type="button" aria-controls="drawer-navigation" className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center">
        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        <span className="sr-only">Close menu</span>
    </button>
           </div>
         <span
          className="flex items-center py-3 pr-4 hover:text-green-500 "
           >
           <span className='flex items-center justify-between'>
            <div>
            <Disclosure>
          {({ open }) => (
            <>
              {/* <Disclosure.Button className="flex mb-2 w-full justify-between text-left font-medium text-black"> */}
              <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-black font-bold">
                <span className="text-md">Programs</span>
                <ChevronDownIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 ml-4 mt-1 text-green-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                <>
                <Link to="/programs/ten-x"
                className='text-blue-600 block px-4 py-2 text-sm' onClick={() => setIsMenuOpen(false)}
              >
                - Zero to Hero 10X
              </Link>

                <Link to="/programs/high-street"
                className='text-blue-600 block px-4 py-2 text-sm' onClick={() => setIsMenuOpen(false)}
              >
                - TSU High Street
              </Link>

              <Link to="/programs/street-mba"
              className='text-blue-600 block px-4 py-2 text-sm' onClick={() => setIsMenuOpen(false)}
            >
              - The Street MBA
            </Link>
            </>

              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

            </div>
            </span>

            </span
         >
         <span
          className="flex items-center py-3 pr-4 hover:text-green-500 "
           >
           <span className='flex items-center justify-between'>
            <div>
            <Disclosure>
          {({ open2 }) => (
            <>
              {/* <Disclosure.Button className="flex mb-2 w-full justify-between text-left font-medium text-black"> */}
              <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-black font-bold">
                <span className="text-md">Services</span>
                <ChevronDownIcon
                  className={`${
                    open2 ? 'rotate-180 transform' : ''
                  } h-5 w-5 ml-4 mt-1 text-green-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                <>
                <Link to="/services/business-accelerator"
                className='text-blue-600 block px-4 py-2 text-sm' onClick={() => setIsMenuOpen(false)}
              >
                - Business Accelerator
              </Link>
            </>

              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

            </div>
            </span>

            </span
         >
         <Link to="/courses/categories"  onClick={() => setIsMenuOpen(false)} aria-label="Course Categories" title="Course Categories"
         className="flex items-center p-4 hover:text-green-500 "
           >
           <span>Categories</span></Link
         >
         <Link to="/events"  aria-label="Events" title="Events"
         onClick={() => setIsMenuOpen(false)}
       className="flex items-center p-4 hover:text-green-500 "
           >
           <span>Events</span></Link
         >
         <a href="https://learn.thestreetuniversity.com/users/sign_in" target="_blank" rel="noopener noreferrer"
         onClick={() => setIsMenuOpen(false)}
         className="inline-flex items-center justify-center h-12 px-6 my-4 ml-2 font-medium tracking-wide text-sm text-black transition duration-200 rounded shadow-md bg-green-500 border border-green-600 hover:bg-green-700 focus:shadow-outline focus:outline-none" aria-label="Sign in" title="Sign in"
           ><span className="mr-2">
             <svg
               fill="none"
               stroke="currentColor"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeWidth="2"
               viewBox="0 0 24 24"
               className="w-6 h-6"
             >
               <path
                 d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
               ></path>
             </svg>
           </span>
           <span>Sign In</span></a
         >
       </aside>
         </div>
       </nav>
      )}
    </div>
  </div>
</div>

</>
  )
}

export default Header