import { Swiper, SwiperSlide } from "swiper/react";
import { useMemo, useCallback } from "react";
import { Autoplay, Pagination } from 'swiper';
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
// import BlogSection from '../components/Landing/BlogSection';
import MidContent from '../components/Landing/MidContent';
import Testimonials from '../components/Landing/Testimonials';
import Programs from "../components/Landing/Programs";

const Landing = () => {


  const determineDate = useCallback(() => {
    const months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ]
  const today = new Date();
  const date1 = today.getDate();

  const month =  date1 < 25 ? months[today.getMonth()] : months[today.getMonth()+2];

return `The Zero to Hero Ten X ${month} cluster`
}, []);


const monthtext = useMemo(() => determineDate(), [determineDate]);


  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-16 lg:items-center sm:px-16">
        <div className="max-w-lg px-4 mx-auto text-left lg:text-left lg:mx-0 lg:px-0">
          <div className="sm:mb-16 lg:mb-0 lg:max-w-lg lg:pr-5 pt-10">
            <div className="max-w-xl mx-2 mb-6">
              <h2 className="mb-6 text-4xl leading-10 font-bold md:leading-tight text-black sm:text-4xl md:mb-4">
                We Empower you to be
                <br className="display-none" />
                <span className="text-black max-w-lg mb-4 font-bold sm:text-4xl text-left">
                  The Next Big Thing.
                </span>
              </h2>
              <p className="animate-pulse text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-purple-800 font-sans font-bold my-2 italic tracking-wide text-sm sm:text-base">
              Register and start your journey today right now !!!
</p>

              <p className="text-gray-900 font-sans tracking-wide text-sm sm:text-base">
                The Street University is a platform dedicated to helping
                entrepreneurs avoid stupid mistakes that can occur in
                everything from business to family life. Entrepreneurs are
                taught by those who have walked the path as they help students
                navigate theirs effectively.
              </p>
              <div className="mt-4">
                <Link
                  to="/register/ten-x"
                  className="btn text-white bg-black hover:bg-gray-800 btn-lg px-4 py-2 rounded text-sm sm:w-auto"
                >
                  Join Us Now
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="grid ">
          <div>
            <img
              className="object-cover"
              src="/thestreetuniversity_landing3.png"
              alt="The Street University"
            />
          </div>
        </div>

      </div>

      {/* What we do */}
      <div className="max-w-xl md:mx-auto sm:text-center ml-2 sm:ml-0 sm:mb-16 lg:max-w-2xl">
        <h2 className="max-w-lg ont-sans text-3xl font-semibold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          What we do
        </h2>
      </div>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        // onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        rewind= {true}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        className="mySwiper"
        modules={[Pagination, Autoplay]}
      >
        <SwiperSlide>
          {" "}
          <div className="grid grid-cols-1 gap-0 bg-white bg-opacity-25 md:grid-cols-2">
            <div className="flex flex-col items-start w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl">
              <div className="sm:mb-16 lg:my-20 lg:max-w-lg lg:pr-5">
                <div className="max-w-xl mb-6">
                  <div>
                    <div className="flex items-center justify-center w-16 h-16 mb-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-teal-900 h-7 w-7"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-black uppercase rounded-full bg-green-400">
                      We Train You
                    </p>
                  </div>
                  <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                    We link you
                    <br className="hidden md:block" />
                    <span className="ml-1 sm:ml-0">with
                      </span>
                    <span className="ml-2 inline-block text-purple-800">
                      Experts
                    </span>
                  </h2>
                  <p className="text-base text-gray-700 md:text-lg">
                    It's not academic, it's not theory. It is practical
                    mentoring from proven executors and great business minds.
                  </p>
                </div>
              </div>
            </div>
            <div className="mx-4 sm:px-6">
              <img
                src="/thestreetuniversity_train.jpg"
                alt="The Street University"
                className="object-cover rounded-md h-72 bg-gray-100 md:h-120"
              // loading="lazy"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="grid grid-cols-1 gap-0 bg-white bg-opacity-25 md:grid-cols-2">
            <div className="flex flex-col items-start w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl">
              <div className="sm:mb-16 lg:my-20 lg:max-w-lg lg:pr-5">
                <div className="max-w-xl mb-6">
                  <div className="flex items-center justify-center w-16 h-16 mb-4">
                    <svg
                      className="text-teal-900 w-7 h-7"
                      viewBox="0 0 24 24"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        points=" 8,5 8,1 16,1 16,5"
                        strokeLinejoin="round"
                      />
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        points="9,15 1,15 1,5 23,5 23,15 15,15"
                        strokeLinejoin="round"
                      />
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        points="22,18 22,23 2,23 2,18"
                        strokeLinejoin="round"
                      />
                      <rect
                        x={9}
                        y={13}
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        width={6}
                        height={4}
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="inline-block px-3 py-px mb-4 rounded text-xs font-semibold tracking-wider text-black uppercase bg-green-700">
                    We invest in your business ideas
                    </p>
                  </div>
                  <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                    We provide grants
                    <br className="hidden md:block" />
                    <span className="ml-1 sm:ml-0">to support
                      </span>
                    <span className="ml-1 inline-block text-purple-800">
                      your business ideas.
                    </span>
                  </h2>
                  <p className="text-base text-gray-700 md:text-lg">
                    There is someone out there with money looking for your
                    idea. This is where they will find you.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="mx-4 sm:px-6">
              <img
                src="/thestreetuniversity_fund.jpg"
                alt="The Street University"
                className="object-cover h-72 bg-gray-100 md:h-full rounded-md"
              // loading="lazy"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-16 lg:pb-20">
            <div className="grid gap-10 lg:grid-cols-2">
              <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
                <div className="flex items-center justify-center w-16 h-16 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-teal-900 w-7 h-7"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                    />
                  </svg>
                </div>
                <div className="max-w-xl mb-6">
                  <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-black uppercase rounded-full bg-green-700">
                    We Launch You
                  </p>
                  <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                    We support you
                    <br className="hidden md:block" />
                    <span className="ml-1 sm:ml-0">
                    every step of
                    </span>
                    <span className="ml-2 inline-block text-green-700">
                      the way
                    </span>
                  </h2>
                  <p className="text-base text-gray-700 md:text-lg">
                    You are not alone. We have a dream team that will make
                    sure the world hears your story.{" "}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center -mx-4 lg:pl-8">
                <div className="flex flex-col items-end px-3">
                  <img
                    className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                    loading="lazy"
                    src="/thestreetuniversity_launch1.jpg"
                    alt="The Street University"
                  />
                  <img
                    className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                    loading="lazy"
                    src="/thestreetuniversity_launch2.jpg"
                    alt="The Street University"
                  />
                </div>
                <div className="px-3">
                  <img
                    className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                  loading="lazy"
                    src="/thestreetuniversity_launch3.jpg"
                    alt="The Street University"
                  />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="text-center mt-4 top-swiper-pagination"></div>

      {/* <section className="bg-white">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img className="w-full h-80" loading="lazy" src="https://images.ctfassets.net/2aefkrtkr5cr/2EKicGbya7H8KGmo38odXe/ebd6c4fa2b9d7ed49224c7e3a9d1e861/thestreetuniversity_landing_image.jpg"
 alt="The street University" />
          <div className="py-10">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Our History
         </h2>
            <p className="my-6 text-black md:text-md">The Street University literally started on the streets as the Zero
to Hero program which was pioneered by Dr. Wale Akinyemi.
This is an initiative developed in 2012 to empower young
people from high-density areas. The target location was set as
Mathare - a high-density slum in Nairobi.
<br/>
<br/>
Historically Mathare has been home to a lot of violent
uprisings during political unrest. The ghetto provides a
very large army of jobless foot soldiers which have been used
by politicians for political wars. Against this backdrop, we had
a singular mission to transform the lives of these people by
transforming their thinking.
<br/>
<br/>
Our message to them was simple - if your mind leaves the
slums, your body will follow. In flowing with the opportunities
for scale provided by the internet and modern technology, we
have now brought the program online where we are able to
reach more people in more locations
</p>

          </div>
        </div>
      </section> */}


      {/* What we teach */}
      <section>
        <div className="max-w-screen-xl sm:mb-16 px-4 sm:pt-4 sm:pb-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-16 lg:items-center">
            <div className="max-w-lg px-4 mx-auto text-left lg:text-left lg:mx-0 lg:px-0">
              <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl">
                What we Teach
              </h2>
              <p className="mt-4 text-lg text-gray-500 text-left">
                The wise person learns from the mistakes of others. The fool
                insists on making their own. Learn from the mistakes and
                experiences of people who have walked the walk and aren’t just
                talkers.{" "}
              </p>
              <Link
                className="animate-pulse inline-block px-5 py-3 mt-8 font-medium text-dark bg-yellow-400 hover:no-underline rounded-lg hover:bg-green-200"
                to="/courses/categories"
              >
                Start Learning
              </Link>
            </div>

            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
              <Link
                to="/courses/categories/sales"
                className="block p-4 hover:no-underline bg-white border border-gray-100 shadow-sm rounded-xl hover:bg-green-100"
              >
                <span className="inline-block p-3 text-gray-900 rounded-lg bg-gray-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                  </svg>
                </span>
                <h6 className="mt-2 font-medium text-gray-900">Sales</h6>
                <p className="mt-1 text-sm text-gray-500 sm:block">
                  Learn how to convert leads to customers.
                </p>
              </Link>
              <Link
                // to="/courses/categories/leadership"
to="#"
                className="block p-4 hover:no-underline bg-white border border-gray-100 shadow-sm rounded-xl hover:bg-green-100"
              >
                <span className="inline-block p-3 text-gray-900 rounded-lg bg-gray-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                    />
                  </svg>
                </span>
                <h6 className="mt-2 font-medium text-gray-900">Leadership</h6>
                <p className="mt-1 text-sm text-gray-500 sm:block">
                  Learn how to become a transformational leader.
                </p>
              </Link>
              <Link
                // to="/courses/categories/money_matters"
to="#"
                className="block p-4 hover:no-underline bg-white border border-gray-100 shadow-sm rounded-xl hover:bg-green-100"
              >
                <span className="inline-block p-3 text-gray-900 rounded-lg bg-gray-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <h6 className="mt-2 font-medium text-gray-900">
                  Money Matters
                </h6>
                <p className="mt-1 text-sm text-gray-500 sm:block">
                  Learn how to handle money.
                </p>
              </Link>
              <Link
to="#"
                // to="/courses/categories/communication"
                className="block p-4 hover:no-underline bg-white border border-gray-100 shadow-sm rounded-xl hover:bg-green-100"
              >
                <span className="inline-block p-3 text-gray-900 rounded-lg bg-gray-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                    />
                  </svg>
                </span>
                <h6 className="mt-2 font-medium text-gray-900">
                  Communication
                </h6>
                <p className="mt-1 text-sm text-gray-500 sm:block">
                  Learn how to effectively get your message across.
                </p>
              </Link>
              <Link
to="#"
                // to="/courses/categories/branding"
                className="block p-4 hover:no-underline bg-white border border-gray-100 shadow-sm rounded-xl hover:bg-green-100"
              >
                <span className="inline-block p-3 text-gray-900 rounded-lg bg-gray-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                    />
                  </svg>
                </span>
                <h6 className="mt-2 font-medium text-gray-900">Branding</h6>
                <p className="mt-1 text-sm text-gray-500 sm:block">
                  Learn how to stand out from the rest.
                </p>
              </Link>
              <Link
                // to="/courses/categories/creativity"
to="#"
                className="block p-4 hover:no-underline bg-white border border-gray-100 shadow-sm rounded-xl hover:bg-green-100"
              >
                <span className="inline-block p-3 text-gray-900 rounded-lg bg-gray-50">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 14l9-5-9-5-9 5 9 5z" />
                    <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                    />
                  </svg>
                </span>
                <h6 className="mt-2 font-medium text-gray-900">
                  Innovation &amp; Creativity
                </h6>
                <p className="mt-1 text-sm text-gray-500 sm:block">
                  Learn how to be disruptive.
                </p>
              </Link>
            </div>

          </div>
        </div>
      </section>

<Programs text={monthtext}/>


      <section className="bg-white">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img className="w-full" loading="lazy" src="/the_street_university_webinar.jpg" alt="The street University webinars" />
          <div className="py-10">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Want to be more involved ?
              <br className="my-5" />
              <span className="my-5 animate-pulse text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-purple-800">
              Join Our Book and Empowerment Club on Wednesday
              </span></h2>
            <p className="my-6 text-gray-700 md:text-lg">Every Wednesday evening we have deep conversations with CEOs and
            Business leaders who share the books they have read and explain how the books have shaped their journey.{" "}</p>
            <a
              href="https://us02web.zoom.us/webinar/register/WN_12lNIpfSQjG-2Aq4qQsKAA"
              target="_blank"
              rel="noopener noreferrer"
              className="animate-pulse inline-flex items-center px-5 py-3 mt-8 font-medium text-white bg-black hover:no-underline rounded-lg hover:bg-black hover:text-white"
            >
              Register
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 w-4 h-4 ml-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </a>
          </div>
        </div>
      </section>





      {/* Metrics and site's features */}
      <MidContent />

      {/* Testimonals */}
      <Testimonials />

      {/* <BlogSection /> */}

      {/* Last call to action */}
      <section className="relative mb-10 text-white bg-gray-500">
        <img
          loading="lazy"
          src="/thestreetuniversity_landing_image.jpg"
          alt="The street University"
          className="absolute inset-0 object-cover w-full h-full opacity-75"
        />
        <div className="relative max-w-screen-xl px-4 py-24 mx-auto sm:px-6 lg:px-8 bg-gray-900 bg-opacity-75">
          <div className="max-w-3xl mx-auto text-center">
            <strong className="inline-block px-3 py-1 text-xs font-semibold text-red-800 uppercase">
              Don't be left behind
            </strong>
            <h2 className="mt-2 text-4xl font-bold text-white sm:text-6xl">
              An investment in knowledge pays the best interest.
            </h2>
            <Link
              to="/register/ten-x"
              className="animate-pulse inline-flex items-center px-5 py-3 mt-8 font-medium text-white border border-white rounded-lg hover:bg-black hover:no-underline"
            >
              Take the dive
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex-shrink-0 w-4 h-4 ml-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Landing;
