import React, { memo } from 'react'
import { Link } from 'react-router-dom'

const Programs = ({ text }) => {
  return (
    <>
      {/* Programs */}
      {/* <div className="max-w-xl mt-8 md:mx-auto sm:text-center ml-2 sm:ml-0 lg:max-w-2xl">
        <h2 className="max-w-lg font-sans text-3xl font-semibold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Our Programs
        </h2>
      </div>

      <section className="max-w-9xl px-4 py-8 sm:py-24 mx-auto text-left md:text-center bg-white-100">
        <div className="grid grid-cols-1 sm:gap-12 sm:grid-cols-3">
          <div className="mt-4 sm:mt-0">
            <h1 className="text-xl mb-4 sm:text-3xl font-bold leading-tight text-gray-900 md:font-extrabold">
              Zero to Hero 10X
            </h1>
            <p className="mb-6 text-sm text-gray-600 text-justify">
              This is a two month fast paced program that consists of one month
              of intense classroom sessions and one month of practical and
              experiential sessions where students are given seed money and are
              guided in the process of starting real companies which will be
              evaluated over the course of the month.{" "}
            </p>
            <div className="justify-center block space-x-0 space-y-2 md:flex md:space-x-2 md:space-y-0">
              <Link
                to="/programs/ten-x"
                className="inline-block px-5 py-3 mt-1 text-sm font-medium text-white bg-blue-500 rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 block">
            <h1 className="text-xl mb-4 sm:text-3xl font-bold leading-tight text-gray-900 md:font-extrabold">TSU High Street</h1>
            <p className="mb-6 text-sm text-gray-600 text-justify">
              The Street University High Street is a membership bundle that gives entrepreneurs access to exclusive content, courses, webinars and more. For $10 a month, entrepreneurs are exposed to content from the best minds in business as well as opportunities that can take their businesses and ideas to the next level.</p>
            <div className="justify-center block space-x-0 space-y-2 md:flex md:space-x-2 md:space-y-0">
              <Link to="/programs/high-street" className="inline-block px-5 py-3 mt-1 text-sm font-medium text-white bg-blue-500 rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline">
                Learn More
              </Link>
            </div>
          </div>
          <div className="mt-4 sm:mt-0">
            <h1 className="text-xl mb-4 sm:text-3xl font-bold leading-tight text-gray-900 md:font-extrabold">
              The Street MBA
            </h1>
            <p className="mb-6 text-sm text-gray-600 text-justify">
              We live in what is probably the most competitive era in all of
              history. Never before this era could a business exist in Nairobi
              and its direct competitor is in china. Never before could a
              business exist with one person – the visionary – and have all
              other functions be outsourced from different parts of the world{" "}
            </p>
            <div className="justify-center block space-x-0 space-y-2 md:flex md:space-x-2 md:space-y-0">
              <Link
                to="/programs/street-mba"
                className="inline-block px-5 py-3 mt-1 text-sm font-medium text-white bg-blue-500 rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section> */}



      {/* Ten X */}
      <div className="px-4 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">

        {/* <section className="relative overflow-hidden text-gray-300 lg:flex mx-6">
        <div className="relative w-full sm:h-96 lg:w-1/2 lg:h-auto">
          <img src="/streetuni-group.jpg" alt="The Street University Tenx Program" className="absolute rounded-lg inset-0 object-cover w-full h-full" />
        </div>
        <div className="w-full p-12 text-center lg:w-1/2 sm:p-16 lg:p-20 lg:text-left">
          <div className="max-w-xl mx-auto lg:ml-0">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold sm:text-5xl sm:leading-extraloose  text-transparent bg-clip-text bg-gradient-to-r from-green-800 to-purple-800 mx-auto">
            {text}
          </h2>            <p className="lg:mt-4  text-black">
            Click on the link below and register to be enrolled in our upcoming Zero to Hero 10X Program this September.

            </p>
            <a
              href="https://forms.gle/uGsFLFpkLLjZTMTu9" rel="noreferrer" target="_blank" title="Seeking sponsorship" className="inline-block px-5 py-3 mt-8 text-sm animate-pulse font-medium text-white bg-blue-600 rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline">
              Seeking sponsorship
            </a>
          </div>
        </div>
      </section> */}

        <section className="relative overflow-hidden sm:mt-28 text-gray-300 lg:flex mx-6">

          <div className="w-full text-center lg:w-1/2 sm:p-16 lg:px-10 lg:py-20 lg:text-left">
            <div className="lg:ml-0">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold sm:text-5xl sm:leading-extraloose  text-black mx-auto">
                Our Highlights
              </h2>            <p className="lg:mt-4 text-black">
                At the Street University we have been working towards meeting several UN Millennium Development Goals.
                And we'd very much like you to be part of this journey.
                So if you'd like to be part of this great and exciting journey please join us by using the below link.
              </p>
              <Link
                to="/register/ten-x" className="inline-block px-5 py-3 mt-8 text-md  font-medium text-white bg-black rounded-lg hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline">
                Join Us
              </Link>
              {/* <a
                href="mailto: partnerships@thestreetuniversity.com" rel="noreferrer" target="_blank" title="Sponsor students" className="inline-block px-5 py-3 mt-8 text-md  font-medium text-white bg-black rounded-lg hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline">
                Sponsor students
              </a> */}
            </div>
          </div>
          <div className="relative w-full  lg:w-1/2">
            <img src="/streetuni-sdgs.png" alt="The Street University Business Accelerator Program" className="absolute rounded-lg inset-0 object-cover w-full " />
          </div>
        </section>

        {/* <div className="grid gap-8 row-gap-12 lg:grid-cols-2">
          <div className="max-w-md sm:mx-auto sm:text-center row-start-2 sm:row-start-1 sm:row-end-2">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
              <svg className="text-teal-900 w-7 h-7" viewBox="0 0 24 24">
                <polyline fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" points=" 8,5 8,1 16,1 16,5" strokeLinejoin="round"></polyline>
                <polyline fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" points="9,15 1,15 1,5 23,5 23,15 15,15" strokeLinejoin="round"></polyline>
                <polyline fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" points="22,18 22,23 2,23 2,18" strokeLinejoin="round"></polyline>
                <rect x="9" y="13" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" width="6" height="4" strokeLinejoin="round"></rect>
              </svg>
            </div>
            <p className="mb-7 sm:mb-5 sm:text-md text-sm text-gray-900">
              Would you like to be part of this great and exciting journey by sponsoring a number of students to the program? Then send us an email using the link provided below and we shall reach back at you as soon as possible. </p>

            <a href="mailto: partnerships@thestreetuniversity.com" rel="noreferrer" target="_blank" title="Sponsor students" className="rounded text-white mt-4 btn p-4 bg-black font-semibold text-md hover:no-underline hover:text-white hover:bg-green-700 hover:-translate-y-1 hover:scale-110 duration-300">Sponsor students</a>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center row-start-1 sm:row-start-1 sm:row-end-2">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
              <svg xmlns="http://www.w3.org/2000/svg" className="text-teal-900 w-7 h-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
              </svg>
            </div>
            <p className="mb-7 sm:mb-14 sm:text-md text-sm text-gray-900">
              Click on the link below and register to be enrolled in our upcoming Zero to Hero 10X Program this September.

            </p>
            <a href="https://forms.gle/uGsFLFpkLLjZTMTu9" rel="noreferrer" target="_blank" title="Seeking sponsorship" className="rounded text-white mt-4 btn p-4 bg-black font-semibold text-md hover:no-underline hover:text-white hover:bg-green-700 hover:-translate-y-1 hover:scale-110 duration-300">Seeking sponsorship</a>

            <Link to="/register/ten-x" className="rounded text-white mt-4 btn p-4 bg-black font-semibold text-md hover:no-underline hover:bg-green-700 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-white">Seek Sponsorship</Link>
          </div>
        </div> */}
      </div>

      {/* Business Accelerator  */}
      <section className="relative overflow-hidden text-gray-300 bg-gray-900 lg:flex">
        <div className="relative w-full sm:h-96 lg:w-1/2 lg:h-auto">
          <img src="/the_street_university_business_accelerator.jpg" alt="The Street University Business Accelerator Program" className="absolute inset-0 object-cover w-full h-full" />
        </div>
        <div className="w-full p-12 text-center lg:w-1/2 sm:p-16 lg:p-24 lg:text-left">
          <div className="max-w-xl mx-auto lg:ml-0">
            <h1 className="text-3xl font-medium">Introducing Business Accelerator Service.</h1>
            <p className="hidden lg:mt-4 lg:block">
              We help aspiring entrepreneurs to take their business ideas from concept to consumer.
              We give the developing companies access to mentoring, strategic support, registrations and all logistical help, recruiting the right teams.
              Getting the business going, monitoring and evaluation where necessary and much more.
            </p>
            <Link
              to="/services/business-accelerator" className="inline-block px-5 py-3 mt-8 text-sm font-medium text-white bg-blue-500 rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline">
              Discover More
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default memo(Programs)