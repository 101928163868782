import React, { memo } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const SuccessStories = () => {

    return (
        <>
            <div className="max-w-xl md:mx-auto sm:text-center my-8 sm:my-10 lg:max-w-2xl">
                <h4 className="inline-block px-3 py-px mb-4 text-md font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    Success Stories
                </h4>
            </div>
            <div className="flex items-center justify-center px-5 py-5">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    // onSlideChange={() => console.log("The street University")}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    rewind={true}
                    pagination={{
                        dynamicBullets: true,
                        clickable: true,
                    }}
                    className="mySwiper"
                    modules={[Pagination, Autoplay]}
                >

                    <SwiperSlide>
                        <div className="w-full mx-auto max-w-4xl rounded-lg bg-white shadow-lg px-5 pt-5 pb-10 text-gray-800">
                            <div className="w-full mb-10">
                                <div className="text-3xl text-indigo-500 text-left leading-tight h-3">
                                    “
                                </div>
                                <p className="text-sm text-gray-600 text-center px-5">
                                    Over the years, the program has successfully transformed the lives of many young people in different ways.
                                    From the gangster who spent time in prison for numerous offenses and who is now a family man who has completed his master’s degree in business, to the ghetto boy who had become a father as a boy.
                                    We helped him get through university and today he works with one of the top banks in Kenya.
                                    We also have the story of the young girl who became a mother as a girl and who today works as an Executive Assistant in a consulting firm.
                                    These represent those who just went through the program for personal transformation.
                                </p>
                                <div className="text-3xl text-indigo-500 text-right leading-tight h-3 -mt-3">
                                    ”
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="w-full mx-auto max-w-4xl rounded-lg bg-white shadow-lg px-5 pt-5 pb-10 text-gray-800">
                            <div className="w-full mb-10">
                                <div className="text-3xl text-indigo-500 text-left leading-tight h-3">
                                    “
                                </div>
                                <p className="text-sm text-gray-600 text-center px-5">
                                    Then there are those who went through the program as a catalyst for their entrepreneurial journey.
                                    For this category, we have helped them to structure and launch their brands and are holding them by the hand to grow the businesses.
                                    One of the most striking of these is the young lady who was a gang member at the age of fourteen but who today runs her own clothes importation business.
                                    We also have a young man who we are currently helping to set up a tea exporting business and another one who we are helping to set up a car repair workshop.
                                </p>
                                <div className="text-3xl text-indigo-500 text-right leading-tight h-3 -mt-3">
                                    ”
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>


                </Swiper>
                <div className="text-center mt-4 top-swiper-pagination"></div>

            </div>
        </>
    )
}

export default memo(SuccessStories)