import React , {memo} from 'react'

import { Link } from 'react-router-dom'

const MidContent = () => {
  return (
    <>
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
          <div className="flex flex-col justify-center">
            <h2 className="animate-bounce max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl">
              Take Charge of your future
            </h2>
            <div className="flex">
              <div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h6 className="mb-2 font-semibold leading-5 text-purple-800 ml-2">
                    Learn anywhere
                  </h6>
                </div>
                <p className="text-sm text-gray-900">
                  We have an awesome e-learning platform that is easily
                  accessible on any browser from anywhere in the world.
                </p>
                <hr className="w-full my-6 border-gray-300" />
              </div>
            </div>
            <div className="flex">
              <div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h6 className="mb-2 font-semibold leading-5 text-purple-800 ml-2">
                    Learn Anytime
                  </h6>
                </div>
                <p className="text-sm text-gray-900">
                  Our lessons are pre-recorded so you can choose from over 175
                  online courses. You can stop and continue from where you left
                  off anytime you want.
                </p>
                <hr className="w-full my-6 border-gray-300" />
              </div>
            </div>
            <div className="flex">
              <div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                    />
                  </svg>
                  <h6 className="mb-2 font-semibold leading-5 text-purple-800 ml-2">
                    Learn on any device
                  </h6>
                </div>
                <p className="text-sm text-gray-900">
                  Take courses on a computer, mobile or any device from anywhere
                  in the world. And enjoy the freedom and customization options
                  that .{" "}
                </p>
              </div>
            </div>
            {/* <div>
              <Link
                className="animate-pulse inline-block px-5 py-3 mt-8 font-medium text-white bg-gray-900 hover:no-underline rounded-lg"
                to="/courses/categories"
              >
                Jump Right In
              </Link>
            </div> */}
          </div>
          <div className="grid grid-cols-2 gap-5">
            <img className="object-cover w-full h-56 col-span-2 rounded shadow-lg" loading="lazy" src="/thestreetuniversity_virtual_learning3.jpg" alt="The street University learning"/>
            <img className="object-cover w-full h-48 rounded shadow-lg" loading="lazy" src="/thestreetuniversity_virtual_learning2.jpg" alt="The street University learning"/>
            <img className="object-cover w-full h-48 rounded shadow-lg" loading="lazy" src="/thestreetuniversity_virtual_learning1.jpg" alt="The street University learning"/>
          </div>
        </div>
      </div>

      {/* Metrics */}
      <div className="px-4 mx-auto sm:max-w-xl md:max-w-full  sm:mb-20 lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
        <div className="grid grid-cols-2 row-gap-8 md:grid-cols-4">
          <div className="text-center md:border-r">
            <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl text-purple-900">
              30,076
            </h6>
            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Enrollments
            </p>
          </div>
          <div className="text-center md:border-r">
            <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl text-purple-900">
              175
            </h6>
            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Courses
            </p>
          </div>
          <div className="text-center md:border-r">
            <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl text-purple-900">
              10,500
            </h6>
            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Minutes Watch Time
            </p>
          </div>
          <div className="text-center">
            <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl text-purple-900">
              30+
            </h6>
            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Facilitators
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(MidContent)