import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import NotFoundComponent from '../components/NotFoundComponent';

const Confirmation = () => {
    const { program } = useParams();
    const [link, setLink] = useState('');
    const [phrase, setPhrase] = useState('');
    const [title, setTitle] = useState('');
    const [closing, setClosing] = useState(false);
    const [reg, setReg] = useState('');

    useEffect(() => {
        if (program === "business_accelerator") {
            setLink('/services/business-accelerator');
            setTitle('for sending us your application');
            setPhrase('We shall review and get back to you soon.');
            setReg('/business-accelerator');
        }
        if (program === "tenx-sponsored") {
            setLink('/programs/ten-x');
            setTitle('for submitting your application.');
            setPhrase('It will be reviewed and you will be contacted within the next 48 hours on whether you have been accepted into the program.');
            setReg('/ten-x');
        }
        // if (program === "high-street") {
        //     setLink('/programs/high-street');
        //     setTitle('joining The TSU High Street program.');
        //     setPhrase('A confirmation link has been sent to your email.');
        //     setClosing((closing) => !closing);
        //     setReg('/high-street');
        // }
        if (program === "ten-x") {
            setLink('/programs/ten-x');
            setTitle(' for taking this big step to making yourself the next big thing!');
            setPhrase('You will receive an email from us indicating the next steps. Let’s get started!');
            setClosing((closing) => !closing);
            setReg('/ten-x');
        }
        if (program === "street-mba") {
            setLink('/programs/street-mba');
            setTitle('joining The Street MBA program.');
            setPhrase('A confirmation link has been sent to your email.');
            setClosing((closing) => !closing);
            setReg('/street-mba');
        }
    }, [program])

    return (
        <>
            {
                (title && phrase && link) ?

                    <section className="home-section">
                        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                            <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
                                <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
                                    <div className="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
                                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                                            {`Thank you ${title}`}
                                        </h2>

                                        <h4 className="text-sm text-blue-600 md:text-md">
                                            <p>{phrase}
                                            </p></h4>
                                    </div>
                                    <div>
                                        <Link to={link} className="mt-12 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition hover:no-underline duration-200 rounded shadow-md bg-black hover:bg-green-500 focus:shadow-outline focus:outline-none">
                                            Got it thanks
                                        </Link>
                                    </div>
                                    {
                                        closing &&
                                        <>
                                            <h4 className="mt-10 mb-4 text-sm text-red-500 md:text-md">
                                                Didn't receive an email?</h4>
                                            <p>If you haven't yet seen an email from us within 3 minutes, a couple of things could have happened:
                                            </p>
                                            <div>
                                                <ul className="space-y-3 mt-4 text-center">
                                                    <li className="flex items-center text-black">
                                                        1. You mistakenly entered the wrong email
                                                    </li>
                                                    <li className="flex items-start text-black">
                                                        2. There may be a typo in the address you used
                                                    </li>
                                                    <li className="flex items-start text-black">
                                                        3. The email may be in your spam folder
                                                    </li>
                                                </ul>
                                                <Link to={`/register${reg}`} className="mt-12 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition hover:no-underline duration-200 rounded shadow-md bg-black hover:bg-green-500 focus:shadow-outline focus:outline-none">
                                                    Re-enter your details and try again
                                                </Link>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <NotFoundComponent />
            }
        </>
    )
}

export default Confirmation