import React, { useState } from "react";
import apiClient from "../../services/api";
import { countries } from "./countries";
import Alert from "../Alert";
import Spinner from "../Spinner";

const TenxSelfSponsorshipForm = () => {
    const [self, setSelf] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        age: "",
        gender: "",
        profession: "",
        sponsorship: "self_sponsored",
        country: ""
    });

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);


        apiClient.post("/tenx/registration", self).then((response) => {
            setStatus(response.data.status);
            setMessage(response.data.message);
            if (response.status === 201 && response.data.status === "success") {
                setIsLoading(false)

                if (response.data.status === 'success') {
                    window.location.pathname = "/confirmation/ten-x";
                }
            }
            else {
                setStatus("error");
                // setMessage("Technical error occured, please try again.")
                setIsLoading(false)
            }
            setTimeout(() => {
                setSelf({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone: "",
                    age: "",
                    gender: "",
                    profession: "",
                    country: "",
                    sponsorship: "self_sponsored"
                });
                setStatus("");
                setMessage("");
            }, 2000);
        });
    };

    const submitValues = (e) => {
        setSelf((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {status !== "" && <Alert status={status} message={message} />}

                    <form id="reg-form" method="POST" className="form" action="" onSubmit={handleSubmit}>
                        <h3 className="text-3xl font-bold my-6">Sign Up</h3>
                        <hr className="mb-6" />
                        <div className="grid grid-cols-8 gap-6">
                            <div className="col-span-8 sm:col-span-4">
                                <div className="form-group">
                                    <label className="text-md font-bold block mb-4">First Name *</label>
                                    <input type="text" onChange={submitValues} value={self.first_name} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="first_name" placeholder="Enter First Name" name="first_name" required />
                                </div>
                            </div>
                            <div className="col-span-8 sm:col-span-4">
                                <div className="form-group">
                                    <label className="text-md font-bold block mb-4">Last Name *</label>
                                    <input type="text" onChange={submitValues} value={self.last_name} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="last_name" placeholder="Enter Last Name" name="last_name" required />
                                </div>
                            </div>
                            <div className="col-span-8 sm:col-span-4">
                                <div className="form-group">
                                    <label className="text-md font-bold block mb-4">Mobile Phone *</label>
                                    <input type="text" onChange={submitValues} value={self.phone} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="phone" placeholder="+XXX XXX XXX XXX" name="phone" required />
                                    <span className="text-xs text-red-600">Number has to be between 10 and 13 characters</span>
                                </div>
                            </div>
                            <div className="col-span-8 sm:col-span-4">
                                <div className="form-group">
                                    <label className="text-md font-bold block mb-4">Email *</label>
                                    <input type="email" onChange={submitValues} value={self.email} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="email" placeholder="Enter Email" name="email" required />
                                </div>
                            </div>
                            <div className="col-span-8 sm:col-span-4">
                                <div className="form-group">
                                    <label className="text-md font-bold block mb-4">Age Group *</label>
                                    <select onChange={submitValues} value={self.age} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" name="age" id="age" required>
                                        <option value=''>Select Age Group</option>
                                        <option value="18-24">18-24</option>
                                        <option value="25-34">25-34</option>
                                        <option value="35-44">35-44</option>
                                        <option value="45-54"> 45-54</option>
                                        <option value="55-64"> 55-64</option>
                                        <option value="65+"> 65+</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-8 sm:col-span-4">
                                <div className="form-group">
                                    <label className="text-md font-bold block mb-4">Gender *</label>
                                    <select onChange={submitValues} value={self.gender} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" name="gender" id="gender" required>
                                        <option value=''>Select Your Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-8 sm:col-span-4">
                                <div className="form-group">
                                    <label className="text-md font-bold block mb-4">Profession *</label>
                                    <input onChange={submitValues} value={self.profession} type="text" className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" id="profession" placeholder="Enter Profession" name="profession" />
                                </div>
                            </div>
                            <div className="col-span-8 sm:col-span-4">
                                <div className="form-group">
                                    <label className="text-md font-bold block mb-4">Country *</label>
                                    <select onChange={submitValues} value={self.country} className="form-control h-12 mt-1 block w-full shadow-sm sm:text-sm border p-2 border-black rounded-md" name="country" id="country" required>
                                        <option value="">Select Country</option>
                                        {
                                            countries && countries.map((country, index) => (
                                                <option key={index} value={country} id="country">{country}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button type="submit" id="submitBtn" className="bg-primary my-4 rounded hover:bg-blue-600 w-full px-4 py-2">Submit</button>
                    </form>

                    {status !== "" && <Alert status={status} message={message} />}
                </>)}
        </>
    )
}

export default TenxSelfSponsorshipForm