import React from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';

const Services = () => {
  const { service } = useParams();
  return (
    <>
      {

        service === 'business-accelerator' && (
          <>
            <div>
              <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
                  <div className="lg:w-1/2">
                    <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                      Business Accelerator Service
                    </h2>
                  </div>
                  <div className="lg:w-1/2">
                    <p className="text-base text-gray-700 md:text-md">
                      This unique service of the Street University helps aspiring entrepreneurs to take their business ideas from concept to consumer. We give the developing companies access to mentoring, strategic support, registrations and all logistical help, recruiting the right teams. getting the business going, monitoring and evaluation where necessary and much more.
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">
                <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
                  <div className="flex flex-col justify-center">
                    <div className="max-w-xl mb-6">
                      <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                        How to join.
                      </h2>
                    </div>
                    <div className="flex">
                      <div className="flex flex-col items-center mr-4">
                        <div>
                          <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                            <svg className="w-4 text-gray-600" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                              <line fill="none" strokeMiterlimit={10} x1={12} y1={2} x2={12} y2={22} />
                              <polyline fill="none" strokeMiterlimit={10} points="19,15 12,22 5,15" />
                            </svg>
                          </div>
                        </div>
                        <div className="w-px h-full bg-gray-300" />
                      </div>
                      <div className="pt-1 pb-4">
                        <p className="mb-2 text-md font-bold text-black">Step 1</p>
                        <p className="text-gray-700">
                          Visit the registration page and provide the requested information on the registration form provided.</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex flex-col items-center mr-4">
                        <div>
                          <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                            <svg className="w-4 text-gray-600" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                              <line fill="none" strokeMiterlimit={10} x1={12} y1={2} x2={12} y2={22} />
                              <polyline fill="none" strokeMiterlimit={10} points="19,15 12,22 5,15" />
                            </svg>
                          </div>
                        </div>
                        <div className="w-px h-full bg-gray-300" />
                      </div>
                      <div className="pt-1 pb-4">
                        <p className="mb-2 text-md font-bold text-black">Step 2</p>
                        <p className="text-gray-700">
                          We shall review your application and reach out to get more information.
                        </p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex flex-col items-center mr-4">
                        <div>
                          <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                            <svg className="w-4 text-gray-600" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                              <line fill="none" strokeMiterlimit={10} x1={12} y1={2} x2={12} y2={22} />
                              <polyline fill="none" strokeMiterlimit={10} points="19,15 12,22 5,15" />
                            </svg>
                          </div>
                        </div>
                        <div className="w-px h-full bg-gray-300" />
                      </div>
                      <div className="pt-1 pb-4">
                        <p className="mb-2 text-md font-bold text-black">Step 3</p>
                        <p className="text-gray-700">
                          We shall assess the work required and get back to you.
                        </p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex flex-col items-center mr-4">
                        <div>
                          <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                            <svg className="w-6 text-gray-600" stroke="currentColor" viewBox="0 0 24 24">
                              <polyline fill="none" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} points="6,12 10,16 18,8" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="pt-1">
                        <p className="mb-2 text-md font-bold text-black">Success! You can now access all lessons and content from our learning platform.</p>
                        <p className="text-gray-700" />
                        <Link to="/register/business-accelerator" className="inline-block px-5 py-3 mt-8 text-lg font-medium text-white bg-blue-500 rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 hover:no-underline" >
                          Apply now
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="sm:mt-20">
                    <img className="object-cover w-full h-56 rounded shadow-lg sm:h-96" src="/the_street_university_business_accelerator.jpg" alt="The Street University Business Accelerator Program" />
                  </div>
                </div>
              </div>
            </div>

          </>
        )}

      {
        (service === '' || service !== 'business-accelerator') && (<>
          <section className="px-4 pb-24 sm:py-24 mx-auto max-w-7xl">
            <div className="w-full mx-auto text-center lg:w-2/3">
              <h1 className="mb-4 text-6xl font-thin text-gray-900">404</h1>
              <p className="mb-3 text-xl font-bold text-gray-900 md:text-2xl">Oh no! We couldn’t find the page you were looking for.</p>
              <p className="mb-3 text-lg font-medium text-gray-700">
                <Link to="/" className="no-underline">Go back to the homepage.</Link>
              </p>
            </div>
          </section>
        </>)
      }



    </>)

}

export default Services